<app-rp-loader></app-rp-loader>
<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-primary-subtle">
            <div class="row">
              <div class="col-8">
                <div class="text-primary p-4">
                  <h5 class="text-dark-secondary font-weight-bold">Welcome Back !</h5>
                  <span class="text-dark-secondary">Sign in to continue with Ripple.</span>
                </div>
              </div>
              <div class="col-4 align-self-end">
                <div class="d-flex justify-content-center">
                  <img src="assets/images/logo.png" alt="" class="img-fluid w-60 mt-1">
                </div>
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo-mini.png" alt="" class="rounded-circle" height="50" width="50">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <!-- <div class="alert alert-success text-center mb-4 p-2 mt-3" *ngIf="otpSuccessMsg" role="alert">
                {{loginValidations.otp_success_msz}}
              </div> -->

              <div class="alert alert-success text-center mb-4 p-2 mt-3" *ngIf="otpSuccessMsg" role="alert">
                <!-- Check if email contains '@' (indicating an email address) -->
                <ng-container *ngIf="f['email'].value.includes('@'); else phoneTemplate">
                  OTP has been sent to your email.
                </ng-container>
                
                <!-- Template for phone number (if the email doesn't contain '@') -->
                <ng-template #phoneTemplate>
                  OTP has been sent to your Phone number.
                </ng-template>
              </div>
              

              

              <div class="alert alert-danger text-center mb-4 p-2 mt-3" *ngIf="otpErrorMsg&&otpResponse?.message" role="alert">
                {{otpResponse?.message}}
              </div>
              <div class="alert alert-danger text-center mb-4 p-2 mt-3"
                *ngIf="loginResponse && loginResponse.error&&loginResponse.error.message" role="alert">
                {{loginResponse?.error?.message}}
              </div>
              <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <!-- @if(error){
                  <alert type="danger" [dismissible]="false">{{ error }}</alert>
                  } -->


                <div class="mb-3">
                  <label for="email">Email / Mobile Number </label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="text" formControlName="email" class="form-control" id="email"
                      placeholder="Enter Email / Mobile" />
                    <button *ngIf="otpField" class="btn btn-primary  ms-0" type="button" id="password-addon"
                      (click)="sendOtp()">
                      Send OTP
                    </button>
                    @if(submitted && f['email'].errors){
                    <div class="invalid-feedback">
                      @if(f['email'].errors['required']){
                      <div>{{loginValidations?.email_mobile_validation}}</div>
                      }
                      <!-- @if(f['email'].errors['email']){
                          <div>Email must be a valid email address</div>
                          } -->
                    </div>
                    }
                  </div>
                </div>


                <!-- <div class="mb-3" *ngIf="passwordField">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="form-control"
                      placeholder="Enter password">
                    <button class="btn btn-light ms-0" type="button" id="password-addon"
                      (click)="toggleFieldTextType()">
                      <i class="mdi mdi-eye-outline"
                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"></i>
                    </button>
                    <div class="invalid-feedback" *ngIf="submitted && f['password'].errors">
                      <span
                        *ngIf="f['password'].errors['required']">{{loginValidations.password_required_validation}}</span>
                    </div>
                  </div>
                </div>

                <div class="mb-3" *ngIf="otpField">
                  <label for="password">OTP</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="password"
                      placeholder="Enter OTP">
                    @if(submitted && f['password'].errors){
                    <div class="invalid-feedback">
                      @if(f['password'].errors['required']){
                      <span>{{loginValidations.otp_required_validation}}</span>
                      }
                    </div>}
                  </div>
                </div> -->

                <div class="mb-3" *ngIf="passwordField">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="form-control"
                      placeholder="Enter password" autocomplete="off">
                    <button class="btn btn-light ms-0" type="button" id="password-addon"
                      (click)="toggleFieldTextType()">
                      <i class="mdi mdi-eye-outline"
                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"></i>
                    </button>
                    <div class="invalid-feedback" *ngIf="submitted && f['password'].errors">
                      <span
                        *ngIf="f['password'].errors['required']">{{loginValidations.password_required_validation}}</span>

                    </div>
                  </div>
                </div>

                <div class="mb-3" *ngIf="otpField">
                  <label for="password">OTP</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                      formControlName="otp_password" placeholder="Enter OTP" autocomplete="off">
                    <button class="btn btn-light ms-0" type="button" id="password-addon"
                      (click)="toggleFieldTextType()">
                      <i class="mdi mdi-eye-outline"
                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"></i>
                    </button>
                    <div class="invalid-feedback" *ngIf="submitted && f['otp_password'].errors">
                      <span
                        *ngIf="f['otp_password'].errors['required']">{{loginValidations.otp_required_validation}}</span>
                      <span *ngIf="f['otp_password'].errors['pattern']">{{loginValidations.otp_pattern}}</span>
                      <div *ngIf="f['otp_password'].errors['maxlength']">{{loginValidations.otp_length_validation}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-check">
                  <input class="form-check-input login-check" (change)="enableOTP($event)" type="checkbox"
                    id="remember-check">
                  <label class="form-check-label" for="remember-check">
                    Login with OTP
                  </label>
                  <!-- @if(otpResponse && otpResponse.message){ 
                    <div class="invalid-feedback">
                      <span>{{otpResponse.message}}</span>
                    </div>}
                    @if(loginResponse && loginResponse.error&&loginResponse.error.message){
                      <div class="invalid-feedback">
                        <span>{{loginResponse.error.message}}</span>
                      </div>} -->
                </div>

                <div id="elmLoader" class="text-center d-none">
                  <div class="spinner-border text-primary avatar-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" (click)="onSubmit()" type="submit">Log In</button>
                </div>
                <!-- <div class="mt-4 text-center">
                    <h5 class="font-size-14 mb-3">Sign in with</h5>
  
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-primary text-white border-primary">
                          <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-info text-white border-info">
                          <i class="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger">
                          <i class="mdi mdi-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div> -->
                <div class="mt-4 text-center">
                  <a routerLink="/auth/forgotPassword" class="text-primary font-bold"><i class="mdi mdi-lock mr-1"></i>
                    Forgot
                    your
                    password?</a>
                </div>

              </form>
            </div>

          </div>
        </div>
        <!-- <div class="mt-5 text-center">
            <p>Don't have an account ? <a routerLink="/auth/signup" class="fw-medium text-primary"> Signup
                now
              </a> </p>
          </div> -->

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->